@font-face {
  font-family: 'brandroid';
  font-weight: lighter;
  src: url('https://statics.pinchos.se/editorial/fonts/BrandonText-Light.otf')
    format('opentype');
}
@font-face {
  font-family: 'brandroid';
  font-weight: normal;
  src: url('https://statics.pinchos.se/editorial/fonts/BrandonText-Regular.otf')
    format('opentype');
}
@font-face {
  font-family: 'brandroid';
  font-weight: bold;
  src: url('https://statics.pinchos.se/editorial/fonts/BrandonText-Bold.otf')
    format('opentype');
}

* {
  font-family: 'brandroid', 'BrandonText', 'Brandon Text', 'Brandon', 'Arial',
    sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100vw;
  height: 100vh;
  background: white;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.App form {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 1em;
  box-sizing: border-box;
  overflow: auto;
  padding-bottom: 75px;
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

button .loader-wrapper.circle {
  margin-left: 5px;
  margin-right: -10px;
}

.error-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: crimson;
  padding: 0 1em;
}
.error {
  max-width: 400px;
}

.logo-wrapper {
  width: 100%;
  text-align: center;
}

.logo-wrapper img {
  display: initial;
}

.instructions {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1em;
}
.instructions p {
  width: 100%;
  max-width: 450px;
  margin: 0;
  padding: 0;
  text-align: center;
}

input {
  color: rgba(66, 66, 66);
  font-size: 18px;
  border: 0;
  border-bottom: 1px solid rgba(66, 66, 66);
  /* border-radius: 4px; */
  background: transparent;
  padding: 0.5em 1em;
  margin: 1em;
  /* width: 100%; */
  /* flex: 1; */
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  transition: border-bottom-width 0.15s ease-in-out;
  box-sizing: border-box;
  height: 44px;
}
input:focus {
  outline-color: rgb(33, 33, 33, 0);
  border-bottom-width: 3px;
}
input::placeholder {
  color: rgba(33, 33, 33, 0.2);
}

input.country-code {
  flex: 0;
  width: 60px;
  margin-right: 0;
  margin-left: 0;
  text-align: end;
  padding-right: 5px;
  padding-left: 5px;
}
input.phone-number {
  margin-left: 6px;
  flex: 0;
  width: 120px;
  text-align: start;
  padding-left: 5px;
  padding-right: 5px;
}

/* unvisited link */
a:link {
  color: rgb(41, 98, 255);
}

/* visited link */
a:visited {
  color: rgb(118, 143, 255);
}

/* mouse over link */
a:hover {
  color: rgb(0, 57, 203);
}

/* selected link */
a:active {
  color: rgb(0, 57, 203);
}

.profile h2 a {
  display: inline-flex;
}

.profile {
  color: rgb(33, 33, 33);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 3em 0;
  overflow: auto;
}
.profile *::selection {
  background: gray;
}
.profile h1,
.profile h2 {
  width: 100%;
  text-align: center;
  margin: 10px;
}

.form-wrapper {
  overflow: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 3em 0;
}

button {
  color: white;
  font-size: 18px;
  border-radius: 3px;
  padding: 0.6em 1.1em;
  min-width: 150px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

button:disabled,
button[type='submit']:disabled,
button[type='button']:disabled {
  opacity: 0.5;
}

button.sign-out {
  margin-top: 3em;
}

button.linky {
  padding: 0;
  margin: 0;
  background: transparent;
  color: rgb(41, 98, 255);
  text-decoration: underline;
  font-size: 1em;
  border: 0;
  width: auto;
  min-width: auto;
  text-transform: initial;
}

.loading-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-wrapper {
  display: flex;
  flex-flow: column;
  color: rgb(33, 33, 33);
  text-align: center;
}

.loader {
  /* background: white;
  border-radius: 30px;*/
  width: 150px;
  height: 50px;
  overflow: visible;
  stroke: rgba(33, 33, 33);
  stroke-width: 10;
  stroke-linejoin: round;
  stroke-linecap: round;
}
.loader circle {
  fill: rgba(255, 255, 255, 0);
}

.grid {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
}
.block {
  display: flex;
  margin: 0.5em;
  padding: 0.9em;
  background: rgb(66, 66, 66);
  color: white;
  border-radius: 3px;
  min-width: 150px;
  align-items: center;
}
.block:hover {
  background: rgba(66, 66, 66, 0.8);
}
.block:hover a {
  color: rgb(255, 255, 255);
}
.block a {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}
.block h3 {
  margin: 0;
}
.block p {
  margin: 0;
}
